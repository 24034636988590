import * as Sentry from '@sentry/remix'
/**
 * By default, Remix will handle hydrating your app on the client for you.
 * You are free to delete this file if you'd like to, but if you ever want it revealed again, you can run `npx remix reveal` ✨
 * For more information, see https://remix.run/file-conventions/entry.client
 */

import { RemixBrowser, useLocation, useMatches } from '@remix-run/react'
import { startTransition, StrictMode, useEffect } from 'react'
import { hydrateRoot } from 'react-dom/client'

Sentry.init({
	dsn: 'https://698b894070bba66c9759fe159ac56555@o4508098459336704.ingest.us.sentry.io/4508098460975104',
	tracesSampleRate: 1,

	integrations: [
		Sentry.browserTracingIntegration({
			useEffect,
			useLocation,
			useMatches,
		}),
		Sentry.replayIntegration({
			maskAllText: true,
			blockAllMedia: true,
		}),
	],

	replaysSessionSampleRate: 0.1,
	replaysOnErrorSampleRate: 1,
})

startTransition(() => {
	hydrateRoot(
		document,
		<StrictMode>
			<RemixBrowser />
		</StrictMode>,
	)
})


if ("serviceWorker" in navigator) {
	window.addEventListener("load", () => {
		if(!window.location.pathname.includes('/admin')) {
			return;		
		}
	  navigator.serviceWorker.register("/firebase-messaging-sw.js").then(registration => {
		console.log('service worker registered');
	  }).catch(err => {
		console.error(err)
	  });
	});
  }